
import React from 'react';
import Img from "gatsby-image"
import { Link } from "gatsby"

const BlogBoxImg = (props) => {
    return (
        <div className={props.imgClass}>
            <Link to={`${props.slug}/`}>
                <Img fluid={props.imgUrl} />
                {props.categorylabelName && <span className="blog-box__label" style={{backgroundColor: props.categoryLabelColour ? props.categoryLabelColour : '#ddd', color: props.categoryLabelFontColour ? props.categoryLabelFontColour : '#777'}}>{props.categorylabelName}</span>}
            </Link>
        </div>
    );
}

export default BlogBoxImg;